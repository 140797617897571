import styled from "styled-components";

export const StyledTodoLayout = styled.div``;

export const StyledTodoHeader = styled.div`
  display: flex;
`;

export const StyledTodoListLayout = styled.div``;

export const StyledTodoItemLayout = styled.ul``;
export const StyledTodoItem = styled.li``;